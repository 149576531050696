/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_include_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import FastClick from '../../../vendor/assets/javascripts/fastclick';
FastClick.attach(document.body);

import Rails from '@rails/ujs';
Rails.start();

import I18n from '../js/utils/i18n';
I18n.attachToWindow();

import widgets from 'widjet';
import {merge, parent} from 'widjet-utils';
import Bootstrap from 'bootstrap/dist/js/bootstrap.js';
import 'widjet-validation';
import VALIDATION_OPTIONS from '../js/widgets/validation';
import '../js/widgets/animations';
import '../js/widgets/remote-form';
import '../js/widgets/remote-link';

window.Bootstrap = Bootstrap;

const isMobile = () => window.innerWidth < 992;
const isInTemplate = el => parent(el, '.tpl') != null;
const isMobileOrInTemplate = el => isMobile() || isInTemplate(el);

const DEFAULT_EVENTS = 'init load page:load turbolinks:load nested:fieldAdded json-form:ready';

const DEFAULT_CONFIG = () => ({
  on: DEFAULT_EVENTS,
  unless: isInTemplate,
});

widgets('remote-link', '.remote-link', DEFAULT_CONFIG());
widgets('remote-form', 'form.remote-form', DEFAULT_CONFIG());

widgets('live-validation', 'input, select, textarea', merge({
  events: 'input change blur',
  inputBuffer: 500,
}, merge(DEFAULT_CONFIG(), VALIDATION_OPTIONS)));
widgets('form-validation', 'form', merge(DEFAULT_CONFIG(), VALIDATION_OPTIONS));
widgets('animation-queue', '[data-animations]', DEFAULT_CONFIG());

document.addEventListener('DOMContentLoaded', (e) => {
  FastClick.attach(document.body);
});

